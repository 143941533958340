import "./bootstrap";
import Vue from "vue";
import axios from "axios";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import Vuetify from "vuetify";
import { isLoggedIn, validateSession } from "./config/auth";
import VueSweetalert2 from "vue-sweetalert2";

import "@mdi/font/css/materialdesignicons.css";
import "sweetalert2/dist/sweetalert2.min.css";

import "vuetify/dist/vuetify.min.css";

import es from "vuetify/es5/locale/es";

import ECharts from "vue-echarts";
import { use } from "echarts/core";

import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import { GridComponent, TooltipComponent } from "echarts/components";
import { translations } from "./locate"; // Importa las traducciones


use([CanvasRenderer, BarChart, GridComponent, TooltipComponent]);

Vue.component("v-chart", ECharts);
Vue.use(Vuetify);
Vue.use(VueSweetalert2);
Vue.prototype.$axios = axios;
Vue.config.productionTip = false;

// Función de traducción global
Vue.prototype.$translate = function (key, defaultValue = "") {
  const currentLocale = this.$root.$data.currentLanguage || "es"; // Idioma actual
  const keys = key.split("."); // Divide la clave en partes (por ejemplo, 'home.buttonSeeMore')
  let result = translations[currentLocale]; // Comienza con las traducciones del idioma actual

  // Recorre las claves anidadas
  for (let i = 0; i < keys.length; i++) {
    if (result[keys[i]] === undefined) {
      return defaultValue || key; // Si no se encuentra la clave, devuelve el valor por defecto
    }
    result = result[keys[i]]; // Navega en el objeto
  }

  return result || defaultValue || key; // Si no se encuentra la traducción, devuelve el valor por defecto
};


router.beforeEach(async (to, from, next) => {
  if (!to.matched.some((record) => record.meta.isPublic) && !isLoggedIn()) {
    next({
      path: "/auth/login",
      query: { redirect: to.fullPath },
    });
  } else {
    await validateSession();

    const nearestWithTitle = to.matched
      .slice()
      .reverse()
      .find((record) => record.meta && record.meta.title);

    if (nearestWithTitle) {
      document.title = to.meta.title;
      router.app.$emit('update-title', document.title);
    }
    next();
  }
});

new Vue({
  router,
  store,
  data: {
    // Recuperar el idioma desde localStorage o usar 'es' por defecto
    currentLanguage: localStorage.getItem('language') || "es", // Idioma por defecto
  },
  vuetify: new Vuetify({
    lang: {
      locales: { es }, // Asegúrate de que las traducciones estén disponibles
      current: localStorage.getItem('language') || "es", // Asignar el idioma recuperado o 'es' por defecto
    },
  }),
  methods: {
    // Método para cambiar el idioma dinámicamente
    setLanguage(lang) {
      this.currentLanguage = lang;
      localStorage.setItem('language', lang); // Guardar el idioma seleccionado en localStorage
      this.$vuetify.lang.current = lang; // Cambiar idioma de Vuetify
      const titleKey = this.$route.meta.title || ""; // Obtén la clave actual del título
      document.title = this.$translate(titleKey); // Actualiza el título al idioma seleccionado

      window.location.reload();
    },
    detectBrowserLanguage() {
      const browserLanguage = navigator.language || navigator.userLanguage;
      // Convertir el idioma en un formato estándar (es, en, etc.)
      return browserLanguage.split('-')[0] || 'es'; // Por defecto 'es' si no se detecta
    },
  },
  created() {
    // Si hay un idioma guardado en localStorage, se actualizará automáticamente
    const savedLanguage = localStorage.getItem('language');
    const browserLanguage = this.detectBrowserLanguage();
    this.currentLanguage = savedLanguage  || browserLanguage;
    this.$vuetify.lang.current = savedLanguage || browserLanguage; // Establecer idioma en Vuetify

    if (!savedLanguage) {
      // Si existe, establecerlo como el idioma actual
      localStorage.setItem('language', browserLanguage); // Guardar idioma detectado
    } 

    // Escuchar el evento 'update-title' para actualizar el título
    this.$on("update-title", (titleKey) => {
      const translatedTitle = this.$translate(`titleApp.${titleKey}`); // Traducir el título
      console.log(`Título traducido: ${translatedTitle}`); // Muestra la traducción en consola
      document.title = translatedTitle; // Actualiza el título de la página
    });
  },
  render: (h) => h(App),
}).$mount("#app");