

export default {
    welcome: "Bienvenido",  
    titleApp:{
        Login: "Login",
    },
    login:{
        welcomeToOkan: "BIENVENIDO A OKAN",
        accessYourWorkspace: "Acceda a su espacio laboral",
        loginWithNetworkUser: "ACCEDER CON USUARIO DE RED",
        continueWithMicrosoft: "Continuar con Microsoft",
        networkUser: "Usuario de red",
        networkUserDescription: "El usuario de red es el que utiliza para iniciar sesión en Windows",
        enterNetworkUser: "Ingrese aquí el usuario de red",
        enterPassword: "Ingrese aquí la contraseña",
        accessWithNetworkUser: "ACCEDER CON USUARIO DE RED",
        password: "Contraseña"
    }
  };