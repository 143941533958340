<template>
    <div class="animation-wrapper">
      <!-- Las partículas se agregarán dinámicamente desde JavaScript -->
    </div>
  </template>
  
  <script>
  // Importamos la imagen desde el directorio de assets
  import snowflakeImage from '../assets/img/snowflake-white.png';
  
  export default {
    name: "Snowfall",
    mounted() {
      const wrapper = this.$el;
  
      if (wrapper) {
        this.maxParticles = 100; // Número máximo de partículas en pantalla
        this.createParticles(20); // Empezamos con 8 partículas iniciales
        this.generateParticlesContinuously(); // Generamos nuevas partículas continuamente
      } else {
        console.error("No se encontró el contenedor .animation-wrapper");
      }
    },
    methods: {
      createParticles(count) {
        const wrapper = this.$el;
  
        if (!wrapper) {
          console.error("No se pudo encontrar el contenedor .animation-wrapper");
          return; // Asegurarse de que el contenedor existe
        }
  
        // Creamos un array para almacenar las partículas
        this.particles = [];
  
        // Creamos las partículas iniciales
        for (let i = 0; i < count; i++) {
          this.createParticle(); // Crear una partícula
        }
  
        // Iniciar el movimiento de las partículas
        this.updateParticles();
      },
  
      createParticle() {
        const wrapper = this.$el;
  
        if (!wrapper) {
          console.error("No se pudo encontrar el contenedor .animation-wrapper");
          return;
        }
  
        // Crear una nueva partícula
        const particle = document.createElement("div");
        particle.classList.add("particle");
  
        // Establecer propiedades aleatorias para la partícula
        const size = Math.random() * 10 + 10; // Tamaño entre 10px y 20px
        const left = Math.random() * 200; // Aumentamos el rango horizontal para más dispersión
        const top = -30; // Empezamos fuera de la pantalla (arriba)
        const opacity = Math.random() * 0.5 + 0.5; // Opacidad entre 0.5 y 1.0
        const speed = Math.random() * 0.2 + 0.3; // Muy lento (entre 0.2 y 0.3)
        const leftSpeed = Math.random() * 0.01 - 0.01; // Movimiento horizontal pequeño entre -0.05 y 0.03
  
        // Almacenamos cada partícula en un objeto
        this.particles.push({
          element: particle,
          size,
          left,
          top,
          opacity,
          speed,
          leftSpeed
        });
  
        // Establecer las propiedades iniciales de la partícula
        particle.style.position = "absolute";
        particle.style.width = `${size}px`;
        particle.style.height = `${size}px`;
        particle.style.left = `${left}vw`; // Posición inicial aleatoria (más dispersa)
        particle.style.top = `${top}px`; // Posición inicial más arriba (fuera de la pantalla)
        particle.style.opacity = opacity;
  
        // Usamos la imagen de la partícula
        particle.style.backgroundImage = `url(${snowflakeImage})`;
        particle.style.backgroundSize = "contain";
        particle.style.backgroundRepeat = "no-repeat";
  
        // Asegurarnos de que las partículas sean visibles
        particle.style.visibility = "visible";
  
        // Agregar la partícula al contenedor
        wrapper.appendChild(particle);
      },
  
      generateParticlesContinuously() {
        // Generar nuevas partículas solo si hay espacio disponible
        setInterval(() => {
          if (this.particles.length < this.maxParticles) {
            this.createParticle();
          }
        }, 120); // Cambia este valor para ajustar la frecuencia de las nuevas partículas
      },
  
      updateParticles() {
        const wrapper = this.$el;
  
        // Actualizar la posición de las partículas en cada cuadro de animación
        const update = () => {
          this.particles.forEach((particle, index) => {
            // Actualizamos la posición de la partícula
            particle.top += particle.speed; // Movimiento hacia abajo
            particle.left += particle.leftSpeed; // Movimiento horizontal suave
  
            // Ajustamos la opacidad en función de la posición vertical
            const maxOpacityDistance = window.innerHeight; // Altura de la pantalla
            const progress = particle.top / maxOpacityDistance; // Progreso relativo
            particle.opacity = Math.max(0, 1 - progress); // Reduce la opacidad conforme cae
  
            // Si la partícula ha pasado el borde inferior de la pantalla, la reiniciamos
            if (particle.top > window.innerHeight) {
              particle.top = -50; // Reiniciar la posición en la parte superior
              particle.left = Math.random() * 100; // Posición aleatoria en el eje X
              particle.opacity = Math.random() * 0.5 + 0.5; // Reiniciar opacidad
            }
  
            // Establecer las nuevas propiedades de la partícula
            particle.element.style.top = `${particle.top}px`;
            particle.element.style.left = `${particle.left}vw`;
            particle.element.style.opacity = particle.opacity;
  
            // Si la partícula se va fuera del borde izquierdo o derecho, la "rebotamos"
            if (particle.left < 0 || particle.left > 100) {
              particle.leftSpeed *= -1; // Cambiar la dirección del movimiento horizontal
            }
          });
  
          // Llamamos a requestAnimationFrame para el siguiente cuadro
          requestAnimationFrame(update);
        };
  
        update(); // Comenzamos la animación
      }
    }
  };
  
  
  
  
  
  </script>
  
  <style scoped>
  /* Fondo claro */
  
  
  
  .animation-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    /* Z-index alto para estar sobre el contenido */
    pointer-events: none;
    /* No interferir con la interacción del usuario */
  
  
  }
  
  .particle {
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 999999;
    /* Asegurarse de que las partículas estén sobre el fondo */
  }
  </style>
  