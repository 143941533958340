export default {
    welcome: "Welcome",
    titleApp:{
        Login: "Login",
    },
    login:{
        welcomeToOkan: "WELCOME TO OKAN",
        accessYourWorkspace: "Access your workspace",
        loginWithNetworkUser: "LOGIN WITH NETWORK USER",
        continueWithMicrosoft: "Continue with Microsoft",
        networkUser: "Network user",
        networkUserDescription: "The network user is the one you use to log in to Windows",
        enterNetworkUser: "Enter the network user here",
        enterPassword: "Enter the password here",
        accessWithNetworkUser: "LOGIN WITH NETWORK USER",
        password: "Password"
    }
};